.parallax {
    /* overflow: hidden; */
    letter-spacing: -2px;
    line-height: 0.8;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
  
  .parallax .scroller {
    display: flex;
    flex-wrap: nowrap;
  }
  
  .parallax span {
    display: block;
    margin-right: 20px;
  }
  